import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPageResult } from 'src/app/entities/global';
import { map, switchMap, tap } from 'rxjs/operators';
import { IRestaurantSet, RestaurantSetType } from 'src/app/entities/restaurant-set';
import { IRestaurant } from 'src/app/entities/restaurant';
import { Observable, of } from 'rxjs';

export interface IUpdateRequest {
  limit: number;
  offset: number;
  type: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
  })
export class RestaurantsService {
  constructor(
    private http: HttpClient,
  ) {

  }

  // ###########################################################
  //  RESTAURANT SETS
  // ###########################################################

  getRestaurantSet(id: string) {
    return this.http.get<IRestaurantSet>(`api://restaurant-sets/${id}`);
  }

  addRestaurantSet(restaurantSet: any) {
    return this.http.post<IRestaurantSet>('api://restaurant-sets/new', restaurantSet);
  }

  setRestaurantSet(id: string, restaurantSet: any) {
    return this.http.patch<IRestaurantSet>(`api://restaurant-sets/${id}`, restaurantSet);
  }

  delRestaurantSet(id: string) {
    return this.http.delete(`api://restaurant-sets/${id}`);
  }

  getRestaurantSets(offset: number = 0, limit: number = 16, name: string = '', type: RestaurantSetType|string = null) {
    const params = [['limit', limit], ['offset', offset]];
    if (name) { params.push(['name', name]); }
    if (type) { params.push(['type', type]); }
    const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
		console.log('query im looking for!', query);
    return this.http.get<IPageResult<IRestaurantSet>>(`api://restaurant-sets?${query}`);
  }

  // ###########################################################
  //  COMPANIES
  // ###########################################################

  // ###########################################################
  //  RESTAURANTS
  // ###########################################################

  getRestaurant(id: string) {
    return this.http.get<IPageResult<IRestaurant>>(`api://restaurants?id=${id}`).pipe(
      map((page) => (page.list?.length ? page.list.shift() : null)),
    );
  }

  getRestaurants(offset: number = 0,
    limit: number = 16,
    search?: {user_id?: string,
    restaurant_set_id?: string,
    name?: string,
    province?: string,
    city?: string,
    suburb?: string,
    group?: string}) {
    let params = [['limit', limit], ['offset', offset]];
    if (search) { params = params.concat(Object.entries(search)); }
    const query = params.map((p) => `${p[0]}=${p[1]}`).join('&');
    return this.http.get<IPageResult<IRestaurant>>(`api://restaurants?${query}`);
  }
}
