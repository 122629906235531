export enum Permission {

  // Used for Admin roles to pass all permission checks
  Super = '*',

  // User Interface sections
  UIViewEnv = 'ui_view_env',
  UIViewBilling = 'ui_view_billing',
  UIViewSupport = 'ui_view_support',

  // May view user(s)
  UserView = 'user_view',

  // May create users
  UserInvite = 'user_invite',

  // May edit a user's details
  UserEdit = 'user_edit',

  // May complete users' profile
  UserCompleteProfile = 'user_complete_profile',

  // May deactivate users
  UserDeactivate = 'user_deactivate',

  // May delete users
  UserRemove = 'user_remove',

  // May see admin only functionality
  AdminView = 'admin_view',

  // May assign restaurants to users (when editing users)
  UsersAssignRestaurants = 'user_set_restaurants',

  // May view restaurant set(s)
  RestaurantSetView = 'restaurant_set_view',

  // May create a restaurant set
  RestaurantSetCreate = 'restaurant_set_create',

  // May edit a restaurant set
  RestaurantSetEdit = 'restaurant_set_edit',

  // May delete restaurant set
  RestaurantSetDelete = 'restaurant_set_delete',

  // May view companies (used when setting up restaurant sets)
  CompaniesView = 'companies_view',

  // May view promotions
  PromoView = 'promotion_view',

  // May create promotions
  PromoCreate = 'promotion_create',

  // May edit promotions - DEPRECATED
  // PromoEdit = 'promotion_edit',

  // May delete promotions
  PromoDelete = 'promotion_delete',

  // May view analytics, orders and reviews
  AnalyticsView = 'analytics_view',

  // May view billing
  BillingView = 'billing_view',

  // May view support
  SupportView = 'support_view',

  // May view reports page
  FinanceView = 'finance_view',

  // May request the generation of a report
  FinanceCreate = 'finance_create',

  // May view campaign list or campaign details
  GroceryCampaignView = 'grocery_campaign_view',

  // May create a new campaign
  GroceryCampaignCreate = 'grocery_campaign_create',

  // May edit and exiting campaign
  GroceryCampaignEdit = 'grocery_campaign_edit',

  // May deactivate grocery users
  GroceryUserDeactivate = 'grocery_user_deactivate',

  // May edit a grocery user's details
  GroceryUserEdit = 'grocery_user_edit',

  // May create grocery users
  GroceryUserInvite = 'grocery_user_invite',

  // May view grocery user(s)
  GroceryUserView = 'grocery_user_view',

  // Online Sales / Adverts
  AdvertsView = 'online_sales_view',
  AdvertsCreate = 'online_sales_create',
  AdvertsEdit = 'online_sales_edit',
  AdvertsDelete = 'online_sales_delete',

	// Group Reporting
	GroupAnalyticsView = 'group_analytics_view',

  // Menus
  MenuView = 'view_menu',
  MenuEdit = 'menu_edit',
}
