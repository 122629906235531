/* eslint-disable no-undef */
import {
  Inject, Injectable, NgZone, PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject, of, throwError, Observable, from, combineLatest,
} from 'rxjs';
import {
  switchMap, tap, catchError, map, shareReplay, distinctUntilChanged, filter, take, skip,
} from 'rxjs/operators';
import { createPopover, createPopup } from '@typeform/embed';

import { environment } from 'src/environments/environment';
import { enterZone } from 'src/app/operators/enterZone';
import { UserRole } from 'src/app/entities/user-role';
import { Permission } from 'src/app/entities/user-permission';
import { IdName, IPageResult } from 'src/app/entities/global';
import { IPromoIncentive } from 'src/app/entities/promo';
import { BrazeService } from 'src/app/modules/braze/braze.service';
import { setUserProperties, setUserId } from '@angular/fire/analytics';
import { SentryErrorHandlerService } from '../sentry-error-handler.service';
import { AuthService, AuthState } from '../auth.service';
import { FirebaseAnalyticsService } from '../firebase_analytics.service';

export interface IResLogin {
  failed?: boolean;
  failReason?: string;
  token_type?: string | 'bearer';
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
}

export enum ProfileState {
  Non = 0,
  Pending = 1,
  Ready = 2,
  Failure = 3,
}

export enum AssignableType {
  Restaurant = 'restaurant',
  Company = 'company',
  Province = 'province',
  Region = 'custom',
  Franchise = 'franchise',
}

export interface IAssignment extends IdName {
  suburb?: string; // only coming in a later back-end version
}

export interface IProfile {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  role_name: UserRole;
  permissions?: Permission[];
  restaurants: any[];
  restaurant_sets: any[];
  assignable_types: AssignableType[];

  // added firebase data
  restaurant_ids: number[];
  restaurant_set_ids: number[];
  promotions_created: number;
  last_promotion_created_at: number; // epoch
  users_invited: number;
  assigned_restaurants: IAssignment[];
  assigned_restaurant_sets: IdName[];

  // client generated
  hue?: number;
}

export interface IProfileState {
  state: ProfileState;
  failureMsg?: any;
  profile?: IProfile;
}

export interface IResRoles {
  roles: {
    name: string,
    permissions?: {
      description: string
    }[]
  }[];
}

export interface ITypeFormUser {
  name?: string,
  email?: string,
  role?: string,
  restaurant_ids?: string
}

@Injectable({
  providedIn: 'root',
  })
export class UserService {
  private static surveyAdded = false;

  // eslint-disable-next-line no-undef
  private authInstance: google.accounts.id.CredentialResponse = null;

  private profileState$: Observable<IProfileState>;
  readonly hasAllRestaurantsVisible$: Observable<boolean>;

  private role: UserRole;
  private perms: Permission[];

  private profileRefreshCue = new BehaviorSubject<any>(null);
  readonly incentivePromotions$: Observable<IPromoIncentive[]>;

  private incentiveCountPromo = new BehaviorSubject<number>(0);
  readonly incentiveCount$: Observable<{[type: string]: number}>;

  private user: ITypeFormUser;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private errorService: SentryErrorHandlerService,
    private braze: BrazeService,
    private _ngZone: NgZone,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private pid: any,
    private ngFA: FirebaseAnalyticsService,
  ) {
    const auth$ = this.auth.authState$.pipe(
      distinctUntilChanged(),
    );

    const clientProfile$ = combineLatest([auth$, this.profileRefreshCue]).pipe(
      switchMap(([state]): Observable<{state: AuthState, profile?: IProfile}> => {
        if (state === AuthState.Access || isPlatformServer(this.pid)) {
          return this.getProfile().pipe(
            catchError((er) => {
              if (er.status === 403) { return of({ first_name: '-', role_name: null, email: null } as any); }
              throw er;
            }),
            map((profile) => ({ state, profile })),
            // retryWhen(er => er.pipe(delay(6000),take(3))),
            // retryWhen(retryStrategy()),
          );
        }
        return of({ state });
      }),
      map((state) => {
        const profileState = { state: ProfileState.Non, profile: state.profile };
        if (profileState.profile) {
          const roleId = state.profile.role_name;
          this.role = roleId;
          this.perms = profileState.profile.permissions;
        } else {
          this.role = null;
          this.perms = [];
        }
        switch (state.state) {
          case AuthState.Pending:
            profileState.state = ProfileState.Pending;
            break;
          case AuthState.Access:
            if (!this.role) {
              profileState.state = ProfileState.Failure;
            } else {
              profileState.state = ProfileState.Ready;
            }
            break;
          default:
            profileState.state = ProfileState.Non;
            break;
        }
        return profileState;
      }),
      tap((state) => {
        if (state.state === ProfileState.Ready) {
          this.errorService.setUser(state.profile);
          if (this.ngFA.analytics) {
            setUserId(this.ngFA.analytics, state.profile?.id);
          }
          const props: any = { ...state.profile };
          delete props.id; // already set as `user_id` in firebase
          props.restaurant_ids = state.profile?.assigned_restaurants?.map?.((r) => r.id);
          props.restaurant_names = state.profile?.assigned_restaurants?.map?.((r) => `${r.name} ${r.suburb || ''}`);
          delete props.assigned_restaurants; // in favour of flattened names
          props.restaurant_set_ids = state.profile?.assigned_restaurant_sets?.map?.((r) => r.id);
          props.restaurant_set_names = state.profile?.assigned_restaurant_sets?.map?.(
            (r) => r.name,
          );
          delete props.assigned_restaurant_sets; // in favour of flattened names
          if (state.profile?.last_promotion_created_at) {
            props.last_promotion_created_at = new Date(
              state.profile.last_promotion_created_at * 1000,
            );
          }
          if (this.ngFA.analytics) {
            const r = props.role_name;
            if (r === UserRole.GroupHead) props.role_name = `restaurant_${r}`;
            setUserProperties(this.ngFA.analytics, props);
          }
          this.braze.idUser(state.profile.email);
        } else {
          this.errorService.setUser(null);
          if (this.ngFA.analytics) {
            setUserId(this.ngFA.analytics, null);
            setUserProperties(this.ngFA.analytics, null);
          }
          this.braze.idUser('public.user@mrdfood.com');
        }
      }),
      shareReplay(1),
    );

    /**
     * NB: The server check was removed for CoFunded2024 --
     * Im putting back the original (line 241) because its breaking /advertising/top-up SSR page
     *
     *
    this.profileState$ = clientProfile$;
    clientProfile$.subscribe((usr) => {
      this.user = {
        name: usr.profile?.first_name,
        email: usr.profile?.email,
        role: usr.profile?.role_name,
        restaurant_ids: usr.profile?.restaurant_ids.join(' - '),
      };
    });	*/

    if (isPlatformServer(this.pid)) {
      this.profileState$ = of({ state: 1 });
    } else {
      this.profileState$ = clientProfile$;
      clientProfile$.subscribe((usr) => {
        this.user = {
          name: usr.profile?.first_name,
          email: usr.profile?.email,
          role: usr.profile?.role_name,
          restaurant_ids: usr.profile?.restaurant_ids.join(' - '),
        };
      });
    }

    this.hasAllRestaurantsVisible$ = this.profileState$.pipe(
      map((profileState) => {
        const r = profileState.profile?.role_name;
        const admin = UserRole.Admin;
        const sHead = UserRole.SalesHead;
        const accMan = UserRole.AccountManager;
        const finMan = UserRole.FinanceManager;
        return r === admin || r === sHead || r === accMan || r === finMan;
      }),
      shareReplay(1),
    );

    this.incentiveCount$ = combineLatest([
      this.incentiveCountPromo,
    ])
      .pipe(
        map(([promos]) => ({ Promos: promos })),
        shareReplay(1),
      );

    this.incentivePromotions$ = combineLatest([this.profileState$, this.profileRefreshCue]).pipe(
      // tap(([state,change]) => console.log("Incentive")),
      switchMap(([state, change]) => {
        if (state.state === ProfileState.Ready) {
          return this.http.get<IPageResult<IPromoIncentive>>('api://incentive/promotion?limit=15');
        }
        return of({ list: [], total: 0, offset: 0 });
      }),
      map((page) => page.list),
      // TODO: update to show real value
      tap((list) => this.incentiveCountPromo.next(list?.length || 0)),
      shareReplay(1),
    );
    this.incentivePromotions$.pipe(take(1)).subscribe(); // call at lease once
  }

  get profile() {
    return this.profileState$;
  }

  isA(role: UserRole | UserRole[]) {
    return typeof role === 'object' ? role.includes(this.role) : this.role === role;
  }

  authorisedTo(reqs: Permission[]) {
    if (this.role === UserRole.Admin) { return true; }
    if (!this.perms) { return false; }
    return !!reqs.find((req) => this.perms.includes(req));
  }

  authorisedToAll(reqs: Permission[]) {
    if (this.role === UserRole.Admin) { return true; }
    if (!this.perms) { return false; }
    return reqs.filter((req) => this.perms.includes(req)).length === reqs.length;
  }

  login(user: string, password: string) {
    this.auth.loginStarted(); // place the auth service in a pending state - TODO: improve this
    const $ = this.http.post<IResLogin>('api://token', {
      grant_type: 'password',
      user,
      password,
    }).pipe(
      map((res) => ({ success: true, ...res })),
    );
    return this.attachLoginProcess($);
  }

  loginGoogle() {
    const $ = of(this.authInstance).pipe(
      switchMap((inst) => (inst ? of(inst) : this.initGoogleAuth())),
      map((data) => data.credential),
      switchMap((token) => this.http.post<IResLogin>('api://token', {
        grant_type: 'authorization_code',
        identity_provider: 'google',
        identity_provider_token: token,
      })),
      catchError((er) => {
        if ('details' in er) { // google oauth error
          console.log('Failed google login:', er.details);
          return of({ failed: true, failReason: 'Unable to log in with Google.' });
        }
        return throwError(er);
      }),
      enterZone(this._ngZone),
    );
    return this.attachLoginProcess($);
  }

  private attachLoginProcess($: Observable<IResLogin>) {
    return $.pipe(
      switchMap((res) => {
        if (res.failed) {
          return throwError({ error: { error: { friendly_message: res.failReason } } });
        }
        return of(res);
      }),
      tap((res) => {
        this.auth.type = res.token_type;
        this.auth.token = res.access_token;
        this.auth.refreshToken = res.refresh_token;
        this.auth.expiryUnixMin = Math.round(Date.now() * 0.001) + res.expires_in * 60;
      }, (er) => {
        this.auth.loginFailed();
      }),
      switchMap((res) => this.profile),
      skip(1), // skip profile subject replay
      filter((state) => state.state !== ProfileState.Pending),
      take(1),
      map((profileState) => profileState.profile),
    );
  }

  getAuthRoles(incPermissions: boolean) {
    return this.http.get<IResRoles>(`api://roles${incPermissions ? '?include_permissions=true' : ''}`);
  }

  getProfile() {
    return this.http.get<IProfile>('api://profile?firebase=true');
  }

  resetPassword(email: string) {
    return this.http.get(`api://user-passwords/${encodeURIComponent(email)}`);
  }
  setPassword(password: string, id: string) {
    return this.http.post(`api://user-passwords/${id}`, { password });
  }

  getChangePasswordId(code: string) {
    return this.http.get<{change_password_id: string}>(`api://user-invitations/${code}`);
  }

  resendInvitationLink(userid: string) {
    return this.http.post<{userid: string}>(`api://user-invitations/resend/${userid}?user_type=restaurant`, {});
  }

  profileChanged() {
    this.profileRefreshCue.next(null);
  }

  getNPSSurveyState() {
    return this.http.get<string[]>('/api/config/nps_survey_toggle');
  }

  addNPSSurveyScript() {
    createPopup('Ca6dZkDC', {
      hidden: {
        name: this.user.name,
        email: this.user.email,
        role: this.user.role,
        restaurant_id: this.user.restaurant_ids,
      },
      open: 'time',
      openValue: 5000,
      size: 100,
      autoClose: 5000,
    });
  } // end NPS survey Script

  addSurveyScript(injectWidget?: boolean) {
    if (!this.isA(UserRole.RestaurantOwner) && !this.isA(UserRole.RestaurantManager)
      && !this.isA(UserRole.PartnerHead) && !this.isA(UserRole.GroupHead)) { return; }
    if (UserService.surveyAdded && injectWidget) { return; }

    const tfPopover = this._document.getElementById('surveyWrapper');
    if (tfPopover) {
      tfPopover.remove();
      UserService.surveyAdded = false;
    }

    if (injectWidget) {
      const tagBody = this._document.getElementsByTagName('body').item(0);
      const surveyWrapper = this._document.createElement('div');
      surveyWrapper.setAttribute('id', 'surveyWrapper');
      tagBody.appendChild(surveyWrapper);

      createPopover('GrStQ7Mr', {
        container: this._document.querySelector('div#surveyWrapper'),
        // width: 320,
        // height: 400,
        hidden: {
          name: this.user.name,
          email: this.user.email,
          role: this.user.role,
          restaurant_id: this.user.restaurant_ids,
        },
        customIcon: 'https://images.typeform.com/images/sbXXyLMiKCNS',
        buttonColor: '#6DCEF5',
        // buttonText: 'Send feedback',
      });

      UserService.surveyAdded = true;
    }
  }

  private initGoogleAuth() {
    return from(new Promise((resolve, reject) => {
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        auto_select: false,
        // If `cancel_on_tap_outside` is set to true (default), the click on "Sign in with Google"
        // somehow gets picked up as a click to dismiss the One Tap popup and then the page hangs.
        // NB! This is (ideally) a temporary fix, because One Tap is not designed to handle a
        // scenario like the user not being signed into a Google account; in an event like that,
        // the prompt simply gets skipped (i.e. One Tap doesn't show).
        cancel_on_tap_outside: false,
        callback: (response) => {
          this.authInstance = response;
          resolve(response);
        },
      });

      google.accounts.id.prompt((notification) => {
        if (notification.isSkippedMoment()
          // TODO: `isNotDisplayed()` will not be allowed in future when FedCM is in full force,
          // but not sure how else to handle the case where user has not granted permission for
          // 3rd-party sign-in.  Preferably the Google sign-in button simply shouldn't be shown at
          // all??  But querying browser permissions isn't straightforward, it seems...
          || notification.isNotDisplayed()
        ) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ details: 'Sign-in popup closed/failed to show' });
        }
      });
    }).then((data: google.accounts.id.CredentialResponse) => data));
  }
}
