<!-- Modal -->
<div modal [(show)]="isListVisible" align="right" [ngClass]="{ 'wrapper' : isBudgetCapActive === true }" (onClose)="cancel()">
	<span class="subTitle" subtitle>
		<strong>{{ superTitle }}</strong><br />
		<em>{{ title }}</em>
	</span>
	<div class="modal-body">
		<p class="align-left" *ngFor="let message of messages">{{ message }}</p>
		<!-- budget capping form -->
		<ng-container *ngIf="isBudgetCapActive">
			<div class="input-group">
				<h6>Current budget cap</h6>
				<input type="text" [(ngModel)]="currBudgetCap" name="" disabled>
			</div>
			<div class="input-group">
				<h6>Increase cap to</h6>
				<input type="text" [(ngModel)]="newBudgetCap" (keypress)="checkNumber($event)">
			</div>
		</ng-container>
		<ng-container *ngIf="isRestaurants">
			<div class="restaurant-list">
				<ul>
					<li *ngFor="let r of restaurantList">{{ r.name }}</li>
				</ul>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer btn-group">
	  <button
	  	*ngIf="!isBudgetCapActive"
	  	type="button"
	  	class="btn primary btn-full"
	  	(click)="complete()"
	  	id="btnAlertOkay">Okay</button>
	  <button
	  	*ngIf="isBudgetCapActive"
	  	type="button"
	  	class="btn primary btn-full"
	  	(click)="updateBudgetCap()"
	  	id="btnAlertOkay"
	  	[disabled]="newBudgetCap.length <= 0">Update</button>
	  	<div class="form-wrapper">
	  		<div *ngIf="error" class="frm_error" [innerHTML]="error_bCapMsg"></div>
	  	</div>
	</div>
</div>
