export const environment = {
  production: true,
  ssrApiHost: 'https://rssp-api.pp.mrd.com',
  expApiHost: 'https://api.pp.mrd.com',
  googleClientId: '723738481382-1e46unaqdc7m8f0h1ltgdc8rmfhnur7b.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyBzRkSlHztsDZl6MtvJjGb_hOWiZosMck8',
    authDomain: 'tdt-preprod-restaurant-portal.firebaseapp.com',
    databaseURL: 'https://tdt-preprod-restaurant-portal.firebaseio.com',
    projectId: 'tdt-preprod-restaurant-portal',
    storageBucket: 'tdt-preprod-restaurant-portal.appspot.com',
    messagingSenderId: '699471605246',
    appId: '1:699471605246:web:54670dd5375b0fc95964af',
    measurementId: 'G-LGPB232WWJ',
  },
  faDebug: false,
  braze: {
    enable: true,
    apiKey: 'df67a52b-52bd-442c-abc0-be3deffc6796',
    baseUrl: 'sdk.fra-01.braze.eu',
    debug: false,
  },
  showBanners: false,
  s3bucket: 'mrd-marketplace-images-435323474400-eu-west-1',
  orderUrl: 'www-v2.pp.mrd.com',
};
